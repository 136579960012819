<template>
  <v-sheet :color="wsBACKGROUND"  >

    <v-sheet   height="110" />

    <v-sheet  class="d-flex py-8  justify-center px-3" color="white" >
      <v-sheet color="transparent" max-width="830" width="100%">
        <h5 class="font-weight-light mb-3">{{ $t('Homepage') }} - {{ $t('News') }} </h5>
        <v-divider style="width: 100%" />
        <h1 class="font-weight-medium py-3">{{ article.title }}</h1>
        <wsChip icon="mdi-calendar">
          <h5 class="font-weight-light">{{ article.date }}</h5>
        </wsChip>
      </v-sheet>
    </v-sheet>
    <v-sheet class="d-flex justify-center pb-6" style="position: relative">
        <v-sheet color="transparent"  class="d-flex mx-auto px-3" max-width="830" width="100%">
          <div style="width: 100%">
            <v-img v-if="article.img" class="wsRoundedHalf mt-6" width="100%" max-width="100%"   :src="article.img" />
            <div class="mt-8 "     v-html="article.content"></div>
          </div>

        </v-sheet>




    </v-sheet>

  </v-sheet>
</template>

<script>

import {mapActions, mapState} from "vuex";

export default {
  name: "news",
  props : ['alias','newsAlias'],
  components : {
  },
  data() {
    return {
      article : {}
    }
  },
  computed : {
    ...mapState('templates',  [ 'news', 'businessAlias', 'editorMode', 'templateVars_init', 'templateVars_current'  ]),
    accentColor() {
      return `color: ${this.parametr('accent_color')}`
    }
  },
  watch : {
    newsAlias() {
      this.loadArticle()
    }
  },
  methods : {
    ...mapActions('news', [ 'GET_BUSINESS_NEWS_PUBLIC']),

    async loadArticle() {
      this.article = await this.GET_BUSINESS_NEWS_PUBLIC(this.newsAlias);
      window.scrollTo(0, 0)
    },

    // techical
    parametr(varName) {
      let param = '';
      if (  this.editorMode ) { param = this.templateVars_current[varName].value }
      if ( !this.editorMode ) { param = this.templateVars_init[varName]    }
      return param
    },

 },
  mounted() {
   this.loadArticle()
    this.$store.state.templates.headerDark = false
  },
  beforeDestroy() {
    this.$store.state.templates.headerDark = true
  }
}
</script>

<style scoped>
.section-content {
  max-width   : 1100px;
  width       : 100%;
  margin      : auto;
}
</style>